<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
            <h5 slot="header" class="mb-0">
              <b-button
                size="sm"
                :to="{ name: 'admin.purchase.merchant.list' }"
                class="mr-2"
                ><i class="fa fa-chevron-left"></i> Буцах</b-button
              >
              <span v-if="this.$route.params.id > 0"
                >Засах:
                <strong class="text-primary">{{ form.name }}</strong></span
              >
              <span v-else>Шинээр нэмэх</span>
            </h5>
            <b-form-group
              id="input-group-parent"
              label="Төрөл:"
              label-for="input-parent"
            >
              <multiselect
                v-model="selectedType"
                :options="types"
                placeholder="Төрөл"
                label="name"
                track-by="id"
                :multiple="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              id="input-group-name"
              label="Нэр:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                type="text"
                required
                placeholder="Нэр"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-code"
              label="Код:"
              label-for="input-code"
            >
              <b-form-input
                id="input-code"
                v-model="form.code"
                required
                placeholder="Код"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-description"
              label="Тайлбар:"
              label-for="input-description"
            >
              <b-form-textarea
                id="input-description"
                v-model="form.description"
                placeholder="Тайлбар"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              id="input-group-registerNumber"
              label="РД:"
              label-for="input-registerNumber"
            >
              <b-form-input
                id="input-registerNumber"
                v-model="form.registerNumber"
                placeholder="РД"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-startDate"
              label="Бүртгэсэн огноо:"
              label-for="input-startDate"
            >
              <b-form-input
                type="date"
                id="input-startDate"
                v-model="form.startDate"
                required
                placeholder="Сонгоно уу"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-endDate"
              label="Дуусах огноо:"
              label-for="input-endDate"
            >
              <b-form-input
                type="date"
                id="input-endDate"
                v-model="form.endDate"
                required
                placeholder="Сонгоно уу"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-successUri"
              label="Success uri:"
              label-for="input-successUri"
            >
              <b-form-input
                id="input-successUri"
                v-model="form.successUri"
                placeholder="url"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-declineUri"
              label="Decline uri:"
              label-for="input-declineUri"
            >
              <b-form-input
                id="input-declineUri"
                v-model="form.declineUri"
                placeholder="url"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-fee"
              label="Байгууллагын шимтгэл:"
              label-for="input-fee"
            >
              <b-form-input
                id="input-fee"
                v-model="form.fee"
                placeholder="Байгууллагын шимтгэл"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-calc_date"
              label="Тооцоо нийлэх өдөр:"
              label-for="input-calc_date"
            >
              <b-form-input
                id="input-calc_date"
                v-model="form.calcDate"
                placeholder="Тооцоо нийлэх өдөр"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-selectedCategories"
              label="Ангилал:"
              label-for="input-selectedCategories"
            >
              <multiselect
                v-model="selectedCategories"
                :options="categories"
                placeholder="Төрөл"
                label="name"
                track-by="id"
                :multiple="true"
              ></multiselect>
            </b-form-group>

            <b-form-group
                id="input-group-info"
                label="Дэлгэрэнгүй мэдээлэл:"
                label-for="input-info"
            >
              <b-form-textarea
                  id="input-info"
                  v-model="form.info"
                  type="text"
                  required
                  placeholder="Дэлгэрэнгүй мэдээлэл"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
                id="input-group-address"
                label="Хаяг:"
                label-for="input-address"
            >
              <b-form-input
                  id="input-address"
                  v-model="form.address"
                  type="text"
                  required
                  placeholder="Хаяг"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-phone"
                label="Утас:"
                label-for="input-phone"
            >
              <b-form-input
                  id="input-phone"
                  v-model="form.phone"
                  type="text"
                  required
                  placeholder="Утас"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-fax"
                label="Факс:"
                label-for="input-fax"
            >
              <b-form-input
                  id="input-fax"
                  v-model="form.fax"
                  type="text"
                  placeholder="Факс"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-social_facebook"
                label="Facebook:"
                label-for="input-social_facebook"
            >
              <b-form-input
                  id="input-social_facebook"
                  v-model="form.socialFacebook"
                  type="text"
                  placeholder="Facebook"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-social_instagram"
                label="Instagram:"
                label-for="input-social_instagram"
            >
              <b-form-input
                  id="input-social_instagram"
                  v-model="form.socialInstagram"
                  type="text"
                  placeholder="Instagram"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-social_twitter"
                label="Twitter:"
                label-for="input-social_twitter"
            >
              <b-form-input
                  id="input-social_twitter"
                  v-model="form.socialTwitter"
                  type="text"
                  placeholder="Twitter"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-social_youtube"
                label="Youtube:"
                label-for="input-social_youtube"
            >
              <b-form-input
                  id="input-social_youtube"
                  v-model="form.socialYoutube"
                  type="text"
                  placeholder="Youtube"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-logo"
                label="Logo:"
                label-for="input-logo"
            >
              <b-form-file
                  id="input-logo"
                  v-model="form.logo"
                  accept="image/*"
                  placeholder="Logo"
              ></b-form-file>
            </b-form-group>
            <img v-if="form.logoPath"
                 :src="form.logoPath"
                 width="150"
                 class="img-thumbnail"
            />

            <b-form-group
                id="input-group-banner"
                label="Banner:"
                label-for="input-banner"
            >
              <b-form-file
                  id="input-banner"
                  v-model="form.banner"
                  accept="image/*"
                  placeholder="Banner"
              ></b-form-file>
            </b-form-group>

            <img v-if="form.bannerPath"
                 :src="form.bannerPath"
                 width="150"
                 class="img-thumbnail"
            />

            <b-form-group id="input-group-4" class="mb-0">
              <b-form-checkbox value="true" v-model="form.isActive" switch
                >Идэвхтэй</b-form-checkbox
              >
            </b-form-group>

            <b-form-group id="input-group-15" class="mb-0">
              <b-form-checkbox value="true" v-model="form.isShowApp" switch
              >Апп дээр харуулах</b-form-checkbox
              >
            </b-form-group>

            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3"
                >Шинэчлэх</b-button
              >
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Uploader from "../../../../store/image-uploader";

export default {
  name: "Menu.edit",
  data: function() {
    return {
      isLoading: false,
      form: {
        typeId: 0,
        id: 0,
        name: "",
        code: "",
        description: "",
        registerNumber: "",
        startDate: "",
        endDate: "",
        successUri: "",
        declineUri: "",
        isActive: false,
        isShowApp: false,
        catIds: "",
        info: "",
        address: "",
        phone: "",
        email: "",
        socialFacebook: "",
        socialTwitter: "",
        socialInstagram: "",
        socialYoutube: "",
        fee: "",
        calcDate: "",
        fax: "",
        logo: [],
        banner: [],
        logoPath: "",
        bannerPath: "",
      },
      selectedType: null,
      selectedCategories: [],
      categories: [],
      show: true,
      roles: [],
      types: [],
      save_url: "AdminWebService/create_menu",
    };
  },
  watch: {
    selectedType: {
      handler(selectedType) {
        this.$data.form.typeId = selectedType.id;
      },
      deep: true,
    },
    selectedCategories: {
      handler(selectedCategories) {
        var ids = [];
        for (var i in selectedCategories) {
          ids.push(selectedCategories[i].id);
        }
        this.$data.form.catIds = ids.join(",");
      },
      deep: true,
    },
  },
  created: function() {
    this.checkSession();
    // this.getRoles()
    // this.getParents()
    this.initData();
    this.loadData();
  },
  methods: {
    initData: function() {
      this.$http
        .get(
          this.$config.API_URL + "PurchaseWebService/get_merchant_type_list",
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.types = response.body.data;
          },
          () => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );

      this.$http
        .get(
          this.$config.API_URL +
            "PurchaseWebService/get_merchant_category_list",
          {
            params: {
              page: 1,
              per_page: 0,
              orderBy: "sort_order",
              parent_id: 0,
              columns: ["code", "name", "sort_order"].join(","),
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.categories = response.body.data;
          },
          () => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    loadData: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "PurchaseWebService/get_merchant",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              id: this.$route.params.id,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;

            if (response.body) {
              this.$data.form = response.body;
              for (let i in this.$data.types) {
                if (this.$data.types[i].id === this.$data.form.typeId.toString()) {
                  this.$data.selectedType = this.$data.types[i];
                }
              }

              const _ids = this.$data.form.catIds.split(",").map(Number);
              this.$data.selectedCategories = [];
              for (let i in this.$data.categories) {
                if (_ids.includes(this.$data.categories[i].id)) {
                  this.$data.selectedCategories.push(this.$data.categories[i]);
                }
              }
            }
          },
          () => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    async onSubmit() {
      this.isLoading = true;

      const uploader = new Uploader();
      console.log('asdfasdf')

      if(this.$data.form.logo) {
        console.log('LOGO')
        const logo = {
          contentType: this.$data.form.logo.type,
          fileName: this.$data.form.logo.name,
          file: this.$data.form.logo,
          folder: "logo/"
        };
        await uploader.uploadImage(logo, this.$data.form.logo.type).then(response => {
          this.$data.form.logoPath = response.Location;
          this.$delete(this.$data.form, 'logo')
        });
      }

      if(this.$data.form.banner) {
        console.log('BANNER')
        const banner = {
          contentType: this.$data.form.banner.type,
          fileName: this.$data.form.banner.name,
          file: this.$data.form.banner,
          folder: "banner/"
        };
        await uploader.uploadImage(banner, this.$data.form.banner.type).then(response => {
          this.$data.form.bannerPath = response.Location;
          this.$delete(this.$data.form, 'banner')
        });
      }

      await (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email),
        this.$http
          .post(
            this.$config.API_URL + "PurchaseWebService/merchant_data_save",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              if (response.body.responseResultType === "SUCCESS") {
                this.$router.push({ name: "admin.purchase.merchant.list" });
              }
            },
            () => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
    },
    onReset(evt) {
      evt.preventDefault();
      this.$data.form = {
        typeId: 0,
        id: 0,
        name: "",
        code: "",
        description: "",
        registerNumber: "",
        startDate: "",
        endDate: "",
        successUri: "",
        declineUri: "",
        isActive: false,
        isShowApp: false,
        info: "",
        address: "",
        phone: "",
        email: "",
        socialFacebook: "",
        socialTwitter: "",
        socialInstagram: "",
        socialYoutube: "",
        fax: "",
        logo: "",
        banner: "",
      };
    },
  },
};
</script>
